/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply a smooth font rendering */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Set the base font family and size */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  background-color: #f4f6f8;
  color: #333;
  line-height: 1.6;
}

/* Style links */
a {
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Style buttons */
button {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
}

/* Add some padding to the main content area */
main {
  padding: 20px;
}

/* Utility classes for margins and paddings */
.mt-1 { margin-top: 8px; }
.mt-2 { margin-top: 16px; }
.mt-3 { margin-top: 24px; }
.mt-4 { margin-top: 32px; }

.mb-1 { margin-bottom: 8px; }
.mb-2 { margin-bottom: 16px; }
.mb-3 { margin-bottom: 24px; }
.mb-4 { margin-bottom: 32px; }

.pt-1 { padding-top: 8px; }
.pt-2 { padding-top: 16px; }
.pt-3 { padding-top: 24px; }
.pt-4 { padding-top: 32px; }

.pb-1 { padding-bottom: 8px; }
.pb-2 { padding-bottom: 16px; }
.pb-3 { padding-bottom: 24px; }
.pb-4 { padding-bottom: 32px; }

/* Flexbox utility classes */
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f4f6f8;
}

::-webkit-scrollbar-thumb {
  background-color: #1976d2;
  border-radius: 10px;
  border: 3px solid #f4f6f8;
}
